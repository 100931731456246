<script>
</script>

<div class="main">
	<img src="/cl-icon-stone-logo.png" alt="" id="left-img" />
	<div class="text-holder">
		<p id="title">MEET COMMUNITY LANDS</p>
		<p>
			We not only aim to connect people with the restorative power of nature, but we empower them to
			achieve their dreams of land ownership, as well as the freedom that accompanies it.
		</p>
		<p>
			Our tribe is filled with lovers of outdoor activities and wellness, seekers of privacy and
			independence, and advocates for sustainability and financial security. They are lifelong
			learners and grow along with us as we inform our community about the nuances of land culture
			and taking everyday life off the grid.
		</p>
		<p>
			Here's to our shared journey towards a future where everyone can find their slice of nature, a
			place to call home, a sanctuary in the wild.
		</p>
	</div>
	<img src="/cl-icon-stone-logo.png" alt="" id="right-img" />
</div>

<style>
	#title {
		color: var(--primary-color);
		font-size: 45.33px;
	}

	.main {
		position: relative;
		display: flex;
		height: 533px;
		justify-content: space-between;
		background-color: var(--white-color);
		overflow: hidden;
	}

	.main img {
		height: 384px;
	}

	.text-holder {
		width: 839px;
		padding: 1.5rem 0;
	}

	.text-holder p {
		text-align: center;
		font-size: 18.67px;
		word-spacing: 4px;
	}

	#right-img,
	#left-img {
		position: relative;
		opacity: 0.3;
		z-index: 0;
	}

	#left-img {
		top: -6rem;
	}

	#right-img {
		bottom: -7rem;
		right: -3rem;
	}

	@media only screen and (max-width: 1250px) {
		#title {
			margin-bottom: 0;
			font-size: 35.33px;
		}
	}

	@media only screen and (max-width: 1100px) {
		#left-img,
		#right-img {
			width: 40%;
		}

		.main {
			height: unset;
		}
	}

	@media only screen and (max-width: 950px) {
		#left-img,
		#right-img {
			width: 20%;
		}
	}

	@media only screen and (max-width: 800px) {
		#title {
			font-size: 30.3px;
		}
	}

	@media only screen and (max-width: 620px) {
		.text-holder p {
			font-size: 17px;
		}
	}

	@media only screen and (max-width: 535px) {
		#title {
			font-size: 28.3px;
			margin-top: 1rem;
		}

		.text-holder p {
			margin: 0.4rem;
		}
	}

	@media only screen and (max-width: 450px) {
		.text-holder {
			padding: 0 3rem;
		}

		.text-holder p {
			font-size: 17px;
		}
		#title {
			font-size: 25px;
			margin-top: 0.4rem;
		}
		#right-img, #left-img {
			display: none;
		}
	}
</style>

