<script>
	import PropertyCard from './properties/PropertyCard.svelte';
	export let props;

	let showPerPage = 3;
</script>

<div class="land-container">
	<h4>LAND MARKETPLACE</h4>

	<div class="lands">
		{#each props as prop, counter}
			{#if counter < showPerPage}
				<PropertyCard bind:prop />
			{/if}
		{/each}
	</div>

	<a href="/properties" id="see-more">SEE MORE LAND</a>
</div>

<style>
	.land-container {
		background-color: var(--white-color);
		height: 800px;
		font-family: LATO;
		padding-top: 3rem;
	}

	.land-container h4 {
		color: var(--primary-color);
		text-align: center;
		margin-top: 0;
		font-size: 45.33px;
		font-weight: 100;
		letter-spacing: 3px;
	}

	.lands {
		display: flex;
		justify-content: center;
		column-gap: 1.5rem;
		padding: 0 2rem;
	}

	#see-more {
		text-decoration: none;
		height: 42px;
		width: 200px;
		background-color: var(--orange-color);
		color: var(--pure-white);
		margin-top: 4.3rem !important;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		letter-spacing: 3px;
	}

	#see-more:hover {
		opacity: 0.8;
		transition: 300ms ease;
	}

	@media only screen and (max-width: 1590px) {
		.lands {
			padding: 0 1.5rem;
		}
	}

	@media only screen and (max-width: 780px) {
		.lands {
			flex-direction: column;
			align-items: center;
			row-gap: 1rem;
		}

		.land-container {
			height: unset;
			padding-bottom: 1.5rem;
		}

		#price {
			width: 75%;
		}
	}
</style>
