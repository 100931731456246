<script>
	export let data;
</script>

<div class="card">
	<h2>{data.title}</h2>
	<div>
		<img src={data.image} alt="" />
	</div>
	<div class="text-holder">
		<p>
			{data.description}
		</p>
	</div>
	<a href={data.btn_action}>{data.btn_text}</a>
</div>

<style>
	.card img {
		height: 200px;
		width: 385px;
	}

	.card {
		background-color: var(--white-color);
		height: 510px;
		width: 400px;
		border-radius: 0.5rem;
		padding-top: 1rem;
	}

	.text-holder {
		margin-bottom: 1rem;
		height: 170px;
		padding: 0 1rem;
		text-align: center;
		word-spacing: 3px;
	}

	.card h2 {
		font-size: 15px;
		font-weight: 100;
		letter-spacing: 3px;
	}

	.card a {
		background-color: var(--orange-color);
		color: var(--pure-white);
		text-decoration: none;
		padding: 0.5rem 0.9rem;
		transition: 300ms ease-in-out;
		letter-spacing: 2px;
	}

	.card a:hover {
		color: var(--orange-color);
		background-color: var(--pure-white);
		transition: 300ms ease-in-out;
	}

	@media only screen and (max-width: 420px) {
		.card {
			width: 95%;
		}
		.card img {
			width: 90%;
		}
	}
</style>
