<script>
	import LookingForCard from './_LookingForCard.svelte';
	const items = [
		{
			title: 'LAND MARKETPLACE',
			image: 'purchase-land.jpg',

			description:
				'Discover a wide range of available properties perfect for building your dream home.',
			btn_text: 'VIEW MARKETPLACE',
			btn_action: '/properties'
		},
		{
			title: 'REVIEW RESOURCES',
			image: 'resources.jpg',
			description: 'Get informed with our comprehensive resources.',
			btn_text: 'GO TO BLOG',
			btn_action: '/communitychronicle'
		},

		{
			title: 'COMMUNITY',
			image: 'community.png',
			description: 'Join a thriving community of landowners and future neighbors.',
			btn_text: 'LEARN MORE',
			btn_action: '/buying-process'
		}
	];
</script>

<div class="looking-for">
	<h2>SO, WHAT ARE YOU LOOKING FOR?</h2>
	<div>
		{#each items as item}
			<LookingForCard data={item} />
		{/each}
	</div>
</div>

<style>
	.looking-for {
		height: 640px;
		background-color: rgb(119, 123, 99);
		text-align: center;
		padding-top: 4rem;
	}

	.looking-for h2 {
		margin: 0;
		color: var(--pure-white);
		font-size: 30px;
		font-weight: 100;
		font-weight: 100;
		letter-spacing: 3px;
	}

	.looking-for div {
		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: 3rem;
		margin-top: 3rem;
	}

	@media only screen and (max-width: 1000px) {
		.looking-for div {
			flex-direction: column;
			row-gap: 1rem;
		}

		.looking-for {
			height: 100%;
			padding-bottom: 2rem;
		}
	}

	@media only screen and (max-width: 420px) {
		.looking-for {
			box-sizing: border-box;
			padding: 1rem;
		}
	}
</style>
