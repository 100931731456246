<script>
	import Hero from './_Hero.svelte';
	import HowItWorks from './_HowItWorks.svelte';
	import Reviews from './_Reviews.svelte';
	import Faq from './_FAQ.svelte';
	import Meet from './_Meet.svelte';
	import Articles from './_Articles.svelte';
	import Lands from './_Lands.svelte';
	import LookingFor from './_LookingFor.svelte';
	export let data;
</script>

<svelte:head>
	<title>Community Lands - Buy Affordable Land in the USA</title>
	<meta
		name="description"
		content="Community Lands is a family-owned business located between New York and Florida that has been selling affordable land in the USA since 2004."
	/>
	<meta property="og:title" content="Community Lands - Buy Affordable Land in the USA" />
	<meta
		property="og:description"
		content="Community Lands is a family-owned business located between New York and Florida that has been selling affordable land in the USA since 2004."
	/>
	<meta name="twitter:title" content="Community Lands - Buy Affordable Land in the USA" />

	<meta
		name="twitter:description"
		content="Community Lands is a family-owned business located between New York and Florida that has been selling affordable land in the USA since 2004."
	/>
</svelte:head>

<div class="main">
	<Hero {data} />
	<Meet />
	<LookingFor />
	<Lands bind:props={data.latest} />
	<!-- <HowItWorks /> -->
	<!-- <Faq /> -->
	<!-- <Reviews /> -->
	<Articles articles={data.articles}/>
</div>

<style>
	.main {
		width: 100%;
	}
</style>
