<script>
	export let articles;

	function handleEncodeCaracters(s) {
		return s.replace(/&#8217;/g, "'").toUpperCase();
	}

	function formatDate(date_str) {
		return date_str.split('T')[0];
	}
</script>

<div class="articles-container">
	<h2>THE COMMUNITY CHRONICLE</h2>
	<div class="articles">
		{#each articles as article}
			<div class="article-card">
				<p id="title">{handleEncodeCaracters(article.subject)}</p>
				<img
					src={`${import.meta.env.VITE_API_URL}/communitychronicle/image/${article.blog_images[0].id}?scaley=470&encoding=webp`}
					alt=""
				/>
				<div>
					<p id="date-created">{formatDate(article.date_created)}</p>
					<p id="category">{article.categories[0].category_name}</p>
					<a href="{import.meta.env.VITE_URL}/communitychronicle/{article.slug}">READ MORE</a>
				</div>
			</div>
		{/each}
	</div>
	<a href="/communitychronicle" id="read-more">READ MORE ARTICLES</a>
</div>

<style>
	.articles-container {
		background-color: #d7d9c7;
		height: 820px;
		padding-top: 3rem;
	}

	.articles-container h2 {
		color: var(--primary-color);
		text-align: center;
		margin-top: 0;
		font-size: 45.33px;
		font-weight: 100;
		letter-spacing: 1px;
	}

	.articles {
		display: flex;
		justify-content: center;
		column-gap: 2.5rem;
	}

	.article-card {
		width: 400px;
		height: 600px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.article-card img {
		height: 300px;
		width: 385px;
	}

	.article-card div {
		background-color: var(--white-color);
		border-radius: 1rem;
		width: 385px;
		margin-top: 2rem;
		text-align: center;
		height: 125px;
	}

	#category {
		margin-top: 0;
	}

	#date-created {
		margin-bottom: 0;
	}

	.article-card a {
		color: #4a4f2f;
		text-decoration: underline;
		text-decoration-color: var(--orange-color);
		text-underline-offset: 0.6rem;
		text-decoration-thickness: 0.1rem;
		transition: text-underline-offset 300ms ease-in-out;
		letter-spacing: 4px;
	}

	#read-more {
		text-decoration: none;
		height: 42px;
		width: 250px;
		background-color: var(--orange-color);
		color: var(--pure-white);
		margin-top: 1.3rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		letter-spacing: 3px;
	}

	#read-more:hover {
		opacity: 0.8;
		transition: 300ms ease;
	}

	.article-card a:hover {
		text-underline-offset: 1rem;
		transition: 300ms ease-in-out;
	}

	#title {
		width: 385px;
		margin-left: 0.5rem;
		word-spacing: 3px;
		text-align: center;
		margin-bottom: 1.5rem;
	}

	@media only screen and (max-width: 1300px) {
		.articles {
			column-gap: 0.5rem;
		}
	}

	@media only screen and (max-width: 1200px) {
		.articles {
			flex-direction: column;
			align-items: center;
			row-gap: 1rem;
		}

		.articles-container {
			height: unset;
			padding-bottom: 2rem;
		}

		.article-card {
			height: unset;
		}

		#learn-more,
		#read-more {
			margin-top: 1rem;
		}
	}

	@media only screen and (max-width: 430px) {
		.article-card,
		.article-card img,
		.article-card div {
			width: 90%;
		}

		#title {
			width: 340px;
			text-align: center;
		}
	}
</style>
