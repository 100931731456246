<script>
</script>

<div class="hero">
	<div class="text-holder">
		<h2>WELCOME TO YOUR PIECE OF AMERICA</h2>
		<p>
			We are Community Lands, the first outdoor lifestyle brand dedicated to helping you achieve
			true freedom in life.
		</p>
		<div class="learn-more">
			<a href="/buying-process">LEARN MORE</a>
		</div>
	</div>
</div>

<style>
	.hero {
		width: 100%;
		height: 700px;
		background: url('/forest-pines-mountains-nature.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: end;
		box-sizing: border-box;
		padding: 0 10.2rem;
		position: relative;
		z-index: 99;
	}

	.hero h2 {
		width: 55%;
		color: var(--primary-color);
		letter-spacing: 5px;
		font-size: 54.33px;
		font-weight: 100;
		margin: 0;
	}
	.hero p {
		width: 55%;
		color: var(--primary-color);
		margin-bottom: 1.7rem;
		font-size: 20.33px;
		word-spacing: 4px;
	}

	.hero a {
		border: 1px solid var(--primary-color);
		color: var(--primary-color);
		text-decoration: none;
		width: 237px;
		height: 53px;
		letter-spacing: 2px;
		font-size: 21.45px;
		display: flex;
		justify-content: center;
		align-items: center;
		letter-spacing: 4px;
	}

	.learn-more {
		width: 56%;
	}

	.learn-more a:hover {
		background-color: var(--primary-color);
		color: var(--pure-white);
		transition: 300ms ease;
	}
	.text-holder {
		display: flex;
		flex-direction: column;
		align-items: end;
	}

	@media only screen and (max-width: 1100px) {
		.hero {
			background-position: right;
		}

		.learn-more a {
			background-color: var(--primary-color);
			color: var(--pure-white);
		}
		.learn-more a:hover {
			color: var(--pure-white);
			background-color: transparent;
		}
	}

	@media only screen and (max-width: 820px) {
		.text-holder {
			align-items: center;
		}
	}

	@media only screen and (max-width: 790px) {
		.hero h2,
		.hero p {
			width: unset;
		}
		.text-holder {
			align-items: start;
		}
	}

	@media only screen and (max-width: 520px) {
		.hero {
			padding: 0 3rem;
		}

		.hero h2 {
			color: var(--pure-white);
		}
	}
</style>
